import { Icon } from '@mdi/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { mdiPlay, mdiPlayCircleOutline } from '@mdi/js';
import { ISlotData } from '../../global';
import { useAppSelector } from '../../hooks';
import { Image } from '../bootstrap';

const CatalogItem: React.FC<{ slot: ISlotData }> = ({ slot }) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  return (
    <div className="d-flex flex-column mx-3" key={slot.gameId}>
      <Image rounded fluid src={`/images/${slot.gameName.replaceAll(' ', '-').toLowerCase()}.jpg`} />
      <h3 className="text-center">{slot.gameName}</h3>
      <div className="d-grid mt-auto">
        <Link className="btn btn-outline-light" to={`/game/${slot.gameId}?isDemo=1`}>
          <Icon className="me-2" path={mdiPlayCircleOutline} size={1} />
          Demo
        </Link>
        {isAuthenticated && (
          <Link className="btn btn-light mt-2" to={`/game/${slot.gameId}`}>
            <Icon className="me-2" path={mdiPlay} size={1} />
            Play
          </Link>
        )}
      </div>
    </div>
  );
};

export default CatalogItem;
