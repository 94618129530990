import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import type { IAuthInput } from '../../global';
import Catalog from '../../pages/Catalog';
import Game from '../../pages/Game';
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import { parseQuery } from '../../utils';
import LoginBtnSection from '../Auth/LoginBtnSection';
import { Container, Image, Navbar } from '../bootstrap';

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  return (
    <>
      <BrowserRouter>
        <Navbar data-bs-theme="dark">
          <Container className="bg-dark justify-content-between">
            <Navbar.Brand as={Link} to="/">
              <Image src="/images/logo.png" alt="Logo" width="100" />
            </Navbar.Brand>
            <LoginBtnSection />
          </Container>
        </Navbar>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/game/:id" Component={Game} />
          <Route path="/catalog" Component={Catalog} />
          <Route path="/login" Component={Login} />
          <Route
            path="*"
            element={
              <Container>
                <h1>Page not found</h1>
              </Container>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
