import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Form } from '../components/bootstrap';
import { useAppDispatch } from '../hooks';
import * as authService from '../services/auth';
import { login } from '../store/authSlice';

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [amount, setAmount] = React.useState<number>(1000000);
  const [currency, setCurrency] = React.useState<string>('FUN');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { token, playerId } = await authService.login({ amount, currency });

    dispatch(
      login({
        token,
        user: { id: playerId },
      }),
    );

    navigate('/');
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(+e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Currency</Form.Label>
          <Form.Control placeholder="Enter currency" value={currency} onChange={(e) => setCurrency(e.target.value)} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
    </Container>
  );
};

export default Home;
