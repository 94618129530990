import http from 'axios';
import { CasinoId } from '../config';
import { Balance, ISlotData } from '../global';
import client from '../gql/client';
import { getSlotGql } from '../gql/query';

interface SlotDetails {
  id: string;
  name: string;
}

export const getBalance = async (token: string): Promise<Balance> => {
  const { data } = await http.get('/api/integration/balance', { headers: { 'x-game-token': token } });

  return data;
};

export const getSlot = async (id: string): Promise<Partial<ISlotData>> => {
  const { data } = await client.query<{ slot: { slot: SlotDetails } }>({
    query: getSlotGql,
    variables: { input: id },
    fetchPolicy: 'network-only',
  });

  return {
    gameId: data.slot?.slot.id,
    gameName: data.slot?.slot.name,
  };
};

export const getSlotsCatalog = async (): Promise<ISlotData[]> => {
  const { data } = await http.get('/api/external/game/list', { params: { casinoId: CasinoId } });

  return data.gameList;
};
