import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { RewriteFrames } from '@sentry/integrations';
import './assets/styles/common.scss';
import App from './components/App/App';
import ErrorBoundary from './components/ErrorBoundary';
import client from './gql/client';
import i18n from './i18next';
import reportWebVitals from './reportWebVitals/reportWebVitals';
import store from './store';

const { APP_VERSION, SENTRY_ENABLED, ENV, SENTRY_DSN } = window.__ENV__;

if (SENTRY_ENABLED) {
  Sentry.init({
    environment: ENV,
    dsn: SENTRY_DSN,
    release: `${process.env['REACT_APP_NAME']}@${APP_VERSION}`,
    attachStacktrace: true,
    integrations: [new RewriteFrames()],
    sampleRate: 0.7,
    autoSessionTracking: false,
    sendClientReports: false,
    // integrations: [new Sentry.Replay(), new RewriteFrames()],
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    //
  });
}

ReactDOM.render(
  <React.Suspense fallback={<div />}>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ErrorBoundary>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </ErrorBoundary>
      </ApolloProvider>
    </Provider>
  </React.Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
