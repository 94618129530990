import http from 'axios';
import { Balance } from '../global';

interface RegisteredPlayer {
  playerId: string;
  token: string;
}

export const login = async (balance: Balance): Promise<RegisteredPlayer> => {
  const { data } = await http.post('/api/internal/register', {
    targetHostname: window.location.hostname,
    playerId: 'e3dbd8c5-24e4-4332-a7a8-2e7119b892a3',
    balance,
  });

  return data;
};

// export default {};
