import _ from 'lodash';
import type { IResource } from '@money.energy/shared-components/dist/resources/d';
import { config } from '../config';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result = await Promise.allSettled(promises);
      const failed = _.filter(
        result,
        (asset: { status: string }) => asset.status === 'rejected',
      ) as PromiseRejectedResult[];

      if (failed.length) {
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = (rejected.reason as { source: string; key: string }).source as string;
            asset.onload = () => {
              if (cb) cb((rejected.reason as { source: string; key: string }).key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        continue;
      }
      success = true;
      break;
    } catch (err) {
      console.error(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isTesting = (): boolean => {
  return window.location.host.includes('testing');
};

export const getCssVariable = (cssVar: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
};

export const replaceSubDomain = (domain: string, baseUrl?: string): string => {
  const url = new URL(baseUrl || window.location.origin);
  const hostnameParts = url.hostname.split('.');

  hostnameParts[0] = domain;
  url.hostname = hostnameParts.join('.');
  return url.toString();
};
