export enum Colors {
  WHITE_COLOR = 0xffffff,
  GAME_COLOR = 0xffcc48,
}

export const CasinoId = '00000001-0000-0000-0000-000000000000';

export enum Variables {
  FONT_FAMILY = 'Noto Sans',
}
